import { motion } from "framer-motion";
import { useState } from "react";
import SelectAmount from "./SelectAmount";
import FundsPendWarning from "./FundsPendWarning";
import SelectAccount from "./SelectAccount";
import SelectGame from "./SelectGame";
import CreateGamepass from "./CreateGamepass";
import OrderConfirmation from "./OrderConfirmation";

function Form() {
  const [step, setStep] = useState(1);
  const [stepsCompleted, setStepsCompleted] = useState([1]);

  const [purchaseData, setPurchaseData] = useState({
    user: {
      username: undefined,
      id: undefined,
    },
    purchase: {
      robux: undefined,
      usd: undefined,
    },
    game: {
      id: undefined,
      universeId: undefined,
      name: undefined,
    },
  });

  const handlePurchaseDataChange = (field, value) => {
    setPurchaseData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleNext = () => {
    setStepsCompleted([...stepsCompleted, step + 1]);
    setStep(step + 1);
  };

  const handleBack = () => {
    setStepsCompleted(stepsCompleted.filter((s) => s !== step));
    setStep(step - 1);
  };

  return (
    <div className="form">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        {step > 2 && (
          <div className="form-progress !mt-12">
            <div
              className="form-progress-bar"
              style={{ width: `${((step - 3) / 3) * 100}%` }}
            />
            <div className="form-progress-circles">
              <div
                className={`form-progress-circle ${
                  stepsCompleted.includes(3) ? "active" : ""
                }`}
              >
                1
              </div>
              <div
                className={`form-progress-circle ${
                  stepsCompleted.includes(4) ? "active" : ""
                }`}
              >
                2
              </div>
              <div
                className={`form-progress-circle ${
                  stepsCompleted.includes(5) ? "active" : ""
                }`}
              >
                3
              </div>
              <div
                className={`form-progress-circle ${
                  stepsCompleted.includes(6) ? "active" : ""
                }`}
              >
                4
              </div>
            </div>
          </div>
        )}
        <div className="formContent">
          {step === 1 && (
            <SelectAmount
              handleNext={handleNext}
              purchaseDataChange={handlePurchaseDataChange}
            />
          )}
          {step === 2 && (
            <FundsPendWarning
              handleNext={handleNext}
              handleBack={handleBack}
              robuxAmount={purchaseData.purchase.robux}
            />
          )}

          {step === 3 && (
            <SelectAccount
              handleNext={handleNext}
              handleBack={handleBack}
              username={purchaseData.user.username}
              purchaseDataChange={handlePurchaseDataChange}
            />
          )}

          {/* Select game */}
          {step === 4 && (
            <SelectGame
              handleNext={handleNext}
              handleBack={handleBack}
              ID={purchaseData.user.id}
              purchaseDataChange={handlePurchaseDataChange}
            />
          )}

          {/* Create gamepass */}
          {step === 5 && (
            <CreateGamepass
              handleNext={handleNext}
              handleBack={handleBack}
              robuxAmount={purchaseData.purchase.robux}
              gameName={purchaseData.game.name}
              gameId={purchaseData.game.id}
              gameUniverseId={purchaseData.game.universeId}
            />
          )}

          {/* Order Confirmation step */}
          {step === 6 && (
            <OrderConfirmation
              //handleNext={handleNext}
              handleBack={handleBack}
              robloxUsername={purchaseData.user.username}
              dollarAmount={purchaseData.purchase.usd}
              robuxAmount={purchaseData.purchase.robux}
              robloxGameId={purchaseData.game.id}
              robloxUserId={purchaseData.user.id}
            />
          )}
        </div>
      </motion.div>
    </div>
  );
}

export default Form;
