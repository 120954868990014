import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import useSWR from "swr";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const COLOR_CODES = {
  robuxStatuses: {
    Pending: "bg-yellow-500",
    Completed: "bg-green-500",
    Error: "bg-red-500",
    Cancelled: "bg-red-500",
  },
  paymentStatuses: {
    PENDING: "bg-yellow-500",
    PROCESS: "bg-yellow-500",
    CONFIRM_CHECK: "bg-yellow-500",
    WRONG_AMOUNT_WAITING: "bg-yellow-500",
    CHECK: "bg-yellow-500",
    PAID: "bg-green-500",
    PAID_OVER: "bg-green-500",
    WRONG_AMOUNT: "bg-red-500",
    FAIL: "bg-red-500",
    CANCEL: "bg-red-500",
    SYSTEM_FAIL: "bg-red-500",
    LOCKED: "bg-red-500",
    REFUND_PROCESS: "bg-blue-600",
    REFUND_FAIL: "bg-blue-600",
    REFUND_PAID: "bg-blue-600",
  },
};

// SWR fetcher
const orderFetcher = async (...args) => {
  const req = await fetch(...args, { credentials: "include" });
  if (!req.ok || req.status !== 200) {
    const error = new Error("An error occurred while fetching the data.");
    error.status = req.status;
    throw error;
  }
  return req.json();
};

const PurchaseHistory = () => {
  const { data, error, isLoading } = useSWR(
    `${process.env.REACT_APP_MAIN_PROTOCOL}://user.${process.env.REACT_APP_MAIN_DOMAIN}/orders`,
    orderFetcher
  );

  if (isLoading) return <div></div>;

  if (!isLoading && error)
    return (
      <div className="flex justify-center md:py-48 py-20">
        <h1 className="text-lg md:text-xl tracking-widest">
          {error.status === 404 ? "No orders found" : "Error loading data"}
        </h1>
      </div>
    );

  return (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2 }}
    >
      <h2 className="text-4xl font-semibold">Purchase History</h2>
      {data.Data.length === 0 ? (
        <p className="text-lg md:text-xl tracking-wider pt-10 opacity-75">
          You do not have any orders.
        </p>
      ) : (
        <div className="overflow-x-scroll overflow-y-scroll">
          <table className="flex flex-col mt-10 min-w-max">
            <thead className="mb-3">
              <tr className="grid grid-cols-7 text-left px-10">
                <th>Order ID</th>
                <th>Date</th>
                <th>R$</th>
                <th>Cost (USD)</th>
                <th>Payment Status</th>
                <th>Robux Status</th>
                <th>Receipt</th>
              </tr>
            </thead>
            <tbody className="flex flex-col gap-4">
              {isLoading ? (
                <>
                  <SkeletonOrder />
                  <SkeletonOrder />
                </>
              ) : (
                data.Data.map((orderDetails, idx) => {
                  const createdAt = new Date(
                    orderDetails.createdAt
                  ).toLocaleString(undefined, {
                    month: "numeric",
                    day: "numeric",
                    year: "numeric",
                  });
                  return (
                    <tr
                      id={idx}
                      className="bg-dark-100 rounded-2xl grid grid-cols-7 px-10 py-5"
                    >
                      <td>
                        <p className="text-sm">{orderDetails.orderId}</p>
                      </td>
                      <td>
                        <p className="text-sm">{createdAt}</p>
                      </td>
                      <td>
                        <p className="text-sm">R$ {orderDetails.robuxAmount}</p>
                      </td>
                      <td>
                        <p className="text-sm">
                          $ {orderDetails.usdAmountInvoiced}
                        </p>
                      </td>
                      <td
                        className={
                          COLOR_CODES.paymentStatuses[
                            orderDetails.paymentStatus
                          ] +
                          " w-fit px-3 rounded-full uppercase text-sm flex items-center font-semibold"
                        }
                      >
                        <p className="text-sm">
                          {orderDetails.paymentStatus.replace(/_/g, " ")}
                        </p>
                      </td>
                      {orderDetails.robuxStatus ? (
                        <td
                          className={
                            COLOR_CODES.robuxStatuses[
                              orderDetails.robuxStatus
                            ] +
                            " w-fit px-3 rounded-full uppercase text-sm flex items-center font-semibold"
                          }
                        >
                          <p className="text-sm">{orderDetails.robuxStatus}</p>
                        </td>
                      ) : (
                        <td className="bg-yellow-500 w-fit px-3 rounded-full uppercase text-sm flex items-center font-semibold">
                          <p className="text-sm">WAITING</p>
                        </td>
                      )}

                      <td className="bg-blue-600 hover:bg-blue-800 duration-200 w-fit px-3 rounded-full uppercase text-sm flex items-center font-semibold">
                        <a
                          href={`/order-details?orderId=${orderDetails.orderId}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Receipt{" "}
                          <i className="pl-2 fas text-sm fa-external-link"></i>
                        </a>
                      </td>
                    </tr>
                  );
                })
              )}
              {/* <tr className="bg-dark-100 rounded-2xl grid grid-cols-7 px-10 py-5">
              <td>1</td>
              <td>01/01/2022</td>
              <td>R$ 1000</td>
              <td>$ 8</td>
              <td className="bg-yellow-500 w-fit px-3 rounded-full uppercase text-sm flex items-center font-semibold">
                Pending
              </td>
              <td className="bg-yellow-500 w-fit px-3 rounded-full uppercase text-sm flex items-center font-semibold">
                Pending
              </td>
              <td className="bg-blue-600 hover:bg-blue-800 duration-200 w-fit px-3 rounded-full uppercase text-sm flex items-center font-semibold">
                <a
                  href={`/order-details?orderId=${"ORDER_DETAILS"}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Receipt <i className="pl-2 fas text-sm fa-external-link"></i>
                </a>
              </td>
            </tr>
            <tr className="bg-dark-100 rounded-2xl grid grid-cols-7 px-10 py-5">
              <td>1</td>
              <td>01/01/2022</td>
              <td>R$ 1000</td>
              <td>$ 8</td>
              <td className="bg-green-500 w-fit px-3 rounded-full uppercase text-sm flex items-center font-semibold">
                completed
              </td>
            </tr>
            <tr className="bg-dark-100 rounded-2xl grid grid-cols-7 px-10 py-5">
              <td>1</td>
              <td>01/01/2022</td>
              <td>R$ 1000</td>
              <td>$ 8</td>
              <td className="bg-red-500 w-fit px-3 rounded-full uppercase text-sm flex items-center font-semibold">
                failed
              </td>
            </tr> */}
            </tbody>
          </table>
        </div>
      )}
    </motion.div>
  );
};

const SkeletonOrder = () => {
  return (
    <tr className="bg-dark-100 rounded-2xl grid grid-cols-7 px-10 py-5">
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td className="w-fit px-3 rounded-full uppercase text-sm flex items-center font-semibold">
        <Skeleton />
      </td>
      <td className="w-fit px-3 rounded-full uppercase text-sm flex items-center font-semibold">
        <Skeleton />
      </td>
      <td className="w-fit px-3 rounded-full uppercase text-sm flex items-center font-semibold">
        <Skeleton />
      </td>
    </tr>
  );
};

/* 
<td className="bg-green-500 w-fit px-3 rounded-full uppercase text-sm flex items-center font-semibold">
  completed
</td>
<td className="bg-red-500 w-fit px-3 rounded-full uppercase text-sm flex items-center font-semibold">
  failed
</td> 
*/

export default PurchaseHistory;
