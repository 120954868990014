import { motion } from "framer-motion";
import { useState } from "react";
import * as RadioGroup from "@radix-ui/react-radio-group";
import { useCaptcha } from "../../context/captchaContext";
import { useOrder } from "../../context/orderContext";
import * as Tooltip from "@radix-ui/react-tooltip";
import * as Popover from "@radix-ui/react-popover";

function Step4({
  //handleNext,
  handleBack,
  robloxUsername,
  robuxAmount,
  dollarAmount,
  robloxGameId,
  robloxUserId,
}) {
  // State
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("crypto");

  // Captcha
  const {
    getTurnstileComponent,
    captchaToken,
    clearCaptchaErrorsAndToken,
    isCaptchaValid,
    captchaError,
  } = useCaptcha();

  // order
  const { submitCryptoOrder, submitStripeOrder, orderError, orderLoading } =
    useOrder();

  const submitOrder = async () => {
    if (selectedPaymentMethod === "crypto") {
      const checkout_url = await submitCryptoOrder(
        dollarAmount,
        robuxAmount,
        robloxUsername,
        robloxUserId,
        robloxGameId,
        captchaToken
      );
      if (checkout_url) window.location.replace(checkout_url);
    } else if (selectedPaymentMethod === "card") {
      const checkout_url = await submitStripeOrder(
        dollarAmount,
        robuxAmount,
        robloxUsername,
        robloxUserId,
        robloxGameId,
        captchaToken
      );
      if (checkout_url) window.location.replace(checkout_url);
    }
  };

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <div>
        <div>
          <h2 className="text-2xl md:text-4xl font-semibold text-center md:text-start">
            Order Overview
          </h2>
          <div className="p-7 my-5 rounded-2xl bg-dark flex items-center justify-between drop-shadow-xl border-2 border-white/25">
            <div className="space-y-5">
              <div>
                <h3 className="opacity-50 text-base whitespace-nowrap">
                  Roblox Username
                </h3>
                <p className="font-medium text-lg">{robloxUsername}</p>
              </div>
              <div>
                <h3 className="opacity-50 text-base">Buying</h3>
                <h3 className="font-medium text-2xl">
                  <span className="text-main">R$</span>{" "}
                  {robuxAmount.toLocaleString()}
                </h3>
              </div>
              {/* Desktop */}
              <div className="hidden md:block">
                <Tooltip.Provider delayDuration={100}>
                  <Tooltip.Root>
                    <Tooltip.Trigger asChild>
                      <div className="flex gap-3">
                        <div>
                          <h3 className="opacity-50 text-base">
                            Receiving{" "}
                            <span className="text-red-600 opacity-100">*</span>
                          </h3>
                          <h3 className="font-medium text-2xl">
                            <span className="text-main">R$</span>{" "}
                            {Math.round(0.7 * robuxAmount).toLocaleString()}
                          </h3>
                        </div>
                        <div className="flex justify-center text-center justify-items-center">
                          <i
                            class="fa fa-question-circle text-2xl text-white/50"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    </Tooltip.Trigger>

                    <Tooltip.Portal>
                      <Tooltip.Content
                        className="text-black z-50 select-none rounded-[4px] bg-white px-[15px] py-[10px] text-[15px] leading-none shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity] data-[state=delayed-hamburgerOpen]:data-[side=bottom]:animate-slideUpAndFade data-[state=delayed-hamburgerOpen]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-hamburgerOpen]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-hamburgerOpen]:data-[side=top]:animate-slideDownAndFade"
                        sideOffset={5}
                        side="right"
                        asChild
                      >
                        <div>
                          <h3>
                            Due to the robux being transferred via a gamepass,
                            Roblox will take 30%<br></br>This means{" "}
                            <span className="underline underline-offset-2">
                              <span className="">
                                you will recieve{" "}
                                {(robuxAmount * 0.7).toLocaleString()} R$
                              </span>{" "}
                              <span className="text-main">(70%)</span>{" "}
                              <span className="">
                                and not {robuxAmount.toLocaleString()} R$
                              </span>
                            </span>
                          </h3>
                          <Tooltip.Arrow className="fill-white" />
                        </div>
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  </Tooltip.Root>
                </Tooltip.Provider>
              </div>
              {/* Mobile */}
              <div className="block md:hidden">
                <Popover.Root>
                  <Popover.Trigger asChild>
                    <div className="flex gap-3">
                      <div>
                        <h3 className="opacity-50 text-base">
                          Receiving{" "}
                          <span className="text-red-600 opacity-100">*</span>
                        </h3>
                        <h3 className="font-medium text-2xl">
                          <span className="text-main">R$</span>{" "}
                          {Math.round(0.7 * robuxAmount).toLocaleString()}
                        </h3>
                      </div>
                      <div className="flex justify-center text-center justify-items-center">
                        <i
                          class="fa fa-question-circle text-2xl text-white/50"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </Popover.Trigger>
                  <Popover.Portal>
                    <Popover.Content
                      className="z-50 rounded-lg bg-white text-black px-2 py-2 w-[275px] shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] will-change-[transform,opacity] data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=top]:animate-slideDownAndFade"
                      sideOffset={5}
                      asChild
                      side="bottom"
                    >
                      <div>
                        <h3>
                          Due to the robux being transferred via a gamepass,
                          Roblox will take 30%<br></br>This means{" "}
                          <span className="underline underline-offset-2">
                            <span className="">
                              you will recieve{" "}
                              {(robuxAmount * 0.7).toLocaleString()} R$
                            </span>{" "}
                            <span className="text-main">(70%)</span>{" "}
                            <span className="">
                              and not {robuxAmount.toLocaleString()} R$
                            </span>
                          </span>
                        </h3>
                        <Popover.Arrow className="fill-white" />
                      </div>
                    </Popover.Content>
                  </Popover.Portal>
                </Popover.Root>
              </div>
              <div className="flex flex-col">
                <h3 className="opacity-50 text-base">Cost</h3>
                <div className="flex gap-2">
                  <h3 className="font-medium text-2xl text-main whitespace-nowrap">
                    ${" "}
                    <span className="text-white">
                      {dollarAmount.toLocaleString()}
                    </span>
                  </h3>
                  <h3 className="font-medium text-2xl">USD</h3>
                </div>
              </div>

              {selectedPaymentMethod === "card" ? (
                <div className="flex flex-col">
                  <h3 className="opacity-50 text-base">Processing Fees</h3>
                  <div className="flex gap-2">
                    <h3 className="font-medium text-2xl text-main whitespace-nowrap">
                      ${" "}
                      <span className="text-white">
                        {(0.3 + 0.05 * Number(dollarAmount))
                          .toFixed(2)
                          .toLocaleString()}
                      </span>
                    </h3>
                    <h3 className="font-medium text-2xl">USD</h3>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col">
                  <h3 className="opacity-50 text-base">Processing Fees</h3>
                  <div className="flex gap-2">
                    <h3 className="font-medium text-2xl text-main whitespace-nowrap">
                      ${" "}
                      <span className="text-white">
                        {(0.3 + 0.04 * Number(dollarAmount))
                          .toFixed(2)
                          .toLocaleString()}
                      </span>
                    </h3>
                    <h3 className="font-medium text-2xl">USD</h3>
                  </div>
                </div>
              )}
              <div className="flex flex-col">
                <h3 className="opacity-50 text-base">Total</h3>
                <div className="flex gap-2">
                  <h3 className="font-medium text-2xl text-main whitespace-nowrap">
                    ${" "}
                    <span className="text-white">
                      {(
                        Number(dollarAmount) +
                        (selectedPaymentMethod === "card"
                          ? 0.3 + 0.05 * Number(dollarAmount)
                          : 0.3 + 0.04 * Number(dollarAmount))
                      )
                        .toFixed(2)
                        .toLocaleString()}
                    </span>
                  </h3>
                  <h3 className="font-medium text-2xl">USD</h3>
                </div>
              </div>
            </div>
            <div>
              <i className="fas fa-receipt md:mx-10 md:text-8xl text-6xl"></i>
            </div>
          </div>
        </div>

        <div>
          <h2 className="text-2xl md:text-4xl font-semibold text-center md:text-start">
            How would you like to pay?
          </h2>
          <div className="p-4 my-5 rounded-2xl bg-dark flex items-center justify-between drop-shadow-xl border-2 border-white/25">
            <div>
              <RadioGroup.Root
                className="flex flex-col gap-3"
                defaultValue="crypto"
                onValueChange={(value) => {
                  setSelectedPaymentMethod(value);
                }}
                aria-label="Choose payment method"
              >
                {/* <div className="flex items-center gap-4">
                  <RadioGroup.Item
                    className="bg-white w-[25px] h-[25px] duration-200 rounded-md shadow-[0_2px_10px] shadow-blackA4 hover:bg-main focus:shadow-[0_0_0_2px] focus:shadow-black outline-none cursor-default"
                    value="card"
                    id="r2"
                  >
                    <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-[11px] after:h-[11px] after:rounded-[50%] after:bg-main" />
                  </RadioGroup.Item>
                  <div className="flex-col md:flex">
                    <p className="text-white text-xl font-medium" htmlFor="r2">
                      Pay using Card
                    </p>
                    <p className="font-light text-sm md:text-lg text-white/50 whitespace-nowrap">
                      Apple Pay, Debit/Credit Card
                    </p>
                  </div>
                </div> */}
                <div className="flex items-center gap-4">
                  <RadioGroup.Item
                    className="bg-white w-[25px] h-[25px] duration-200 rounded-md shadow-[0_2px_10px] shadow-blackA4 hover:bg-main focus:shadow-[0_0_0_2px] focus:shadow-black outline-none cursor-default"
                    value="crypto"
                    id="r2"
                  >
                    <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-[11px] after:h-[11px] after:rounded-[50%] after:bg-main" />
                  </RadioGroup.Item>
                  <div className="flex-col md:flex">
                    <p className="text-white text-xl font-medium" htmlFor="r2">
                      Pay using Crypto
                    </p>
                    {/* Desktop */}
                    <p className="hidden md:block font-light text-sm md:text-lg text-white/50 whitespace-nowrap">
                      All Popular Crypto |{" "}
                      <span className="text-main/75">Lower Fees</span>
                    </p>
                    {/* Mobile */}
                    <p className="block md:hidden font-light text-sm md:text-lg text-white/50 whitespace-nowrap">
                      All Popular Crypto
                    </p>
                    <p className="block md:hidden font-light text-sm md:text-lg text-main/75 whitespace-nowrap">
                      Lower Fees
                    </p>
                  </div>
                </div>
              </RadioGroup.Root>
            </div>
          </div>
        </div>

        {/* Captcha */}
        <div className="flex justify-center gap-2 pb-2">
          {getTurnstileComponent()}
        </div>
        {captchaError && (
          <div className="flex justify-center">
            <p className="text-red-500 text-sm lg:text-base">{captchaError}</p>
          </div>
        )}
        {orderError && (
          <div className="flex justify-center">
            <p className="text-red-500 text-sm lg:text-base">{orderError}</p>
          </div>
        )}
        {orderLoading && (
          <div className="flex justify-center">
            <p className="text-sm lg:text-base">Loading...</p>
          </div>
        )}
        <div className="flex gap-4 justify-center md:justify-end">
          <button
            type="button"
            className="cta-cancel !py-2 w-32 text-xl text-center"
            onClick={() => {
              clearCaptchaErrorsAndToken();
              handleBack();
            }}
          >
            Back
          </button>
          <button
            type="button"
            disabled={!isCaptchaValid}
            className="cta !py-2 w-32 text-xl text-center"
            onClick={() => {
              submitOrder();
              //handleNext
            }}
          >
            Purchase
          </button>
        </div>
      </div>
    </motion.div>
  );
}

export default Step4;
