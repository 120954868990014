import { createContext, useContext, useEffect, useState } from "react";
import { useCaptcha } from "./captchaContext";

const OrderContext = createContext();

export const OrderContextProvider = ({ children }) => {
  // State
  const [orderError, setOrderError] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);

  // Captcha State
  const { resetCaptcha } = useCaptcha();

  const submitCryptoOrder = async (
    usdAmount,
    robuxAmount,
    robloxUsername,
    robloxUserId,
    robloxGameId,
    captchaToken
  ) => {
    setOrderLoading(true);
    setOrderError(false);
    try {
      const req = await fetch(
        `${process.env.REACT_APP_MAIN_PROTOCOL}://purchase.${process.env.REACT_APP_MAIN_DOMAIN}/crypto-checkout`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "cf-turnstile-response": captchaToken,
          },
          body: JSON.stringify({
            usdAmount,
            robuxAmount,
            robloxUsername,
            robloxUserId,
            robloxGameId,
          }),
        }
      );

      if (req.status !== 200) {
        const json = (await req.json()) || null;
        if (json && "Message" in json) return setOrderError(json.Message);
        else {
          setOrderError("Request to checkout failed, try again later");
          return false;
        }
      }

      const json = await req.json();
      const url = json.Data.url;

      return url;
    } catch (error) {
      resetCaptcha();
      setOrderError(error);
      return false;
    } finally {
      setOrderLoading(false);
    }
  };

  const submitStripeOrder = async (
    usdAmount,
    robuxAmount,
    robloxUsername,
    robloxUserId,
    robloxGameId,
    captchaToken
  ) => {
    setOrderLoading(true);
    setOrderError(false);
    try {
      const req = await fetch(
        `${process.env.REACT_APP_MAIN_PROTOCOL}://purchase.${process.env.REACT_APP_MAIN_DOMAIN}/stripe-checkout-session`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "cf-turnstile-response": captchaToken,
          },
          body: JSON.stringify({
            usdAmount,
            robuxAmount,
            robloxUsername,
            robloxUserId,
            robloxGameId,
          }),
        }
      );

      if (req.status !== 200) {
        const json = (await req.json()) || null;
        if (json && "Message" in json) return setOrderError(json.Message);
        else {
          setOrderError("Request to checkout failed, try again later");
          return false;
        }
      }

      const json = await req.json();
      const url = json.Data.url;

      return url;
    } catch (error) {
      resetCaptcha();
      setOrderError(error);
      return false;
    } finally {
      setOrderLoading(false);
    }
  };

  const context = {
    orderError,
    orderLoading,
    submitCryptoOrder,
    submitStripeOrder,
  };

  return (
    <OrderContext.Provider value={context}>{children}</OrderContext.Provider>
  );
};

export const useOrder = () => useContext(OrderContext);
