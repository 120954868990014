import * as Tooltip from "@radix-ui/react-tooltip";
import * as Popover from "@radix-ui/react-popover";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useSWR from "swr";
import { withAuth } from "../utils/withAuth";

// SWR fetcher
const orderFetcher = async (...args) => {
  const req = await fetch(...args, { credentials: "include" });
  if (!req.ok || req.status !== 200) {
    const error = new Error("An error occurred while fetching the data.");
    error.status = req.status;
    throw error;
  }
  return req.json();
};

const OrderDetails = () => {
  const [searchParams] = useSearchParams();
  const [orderId] = useState(() => {
    return searchParams.get("orderId");
  });
  const [orderData, setOrderData] = useState({});

  const ref = useRef(null);
  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  const { error, isLoading } = useSWR(
    orderId
      ? `${process.env.REACT_APP_MAIN_PROTOCOL}://purchase.${process.env.REACT_APP_MAIN_DOMAIN}/details?orderId=` +
          orderId
      : null,
    orderFetcher,
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Retry only once
        if (retryCount >= 1) return;

        // Retry after 5 seconds
        setTimeout(() => revalidate({ retryCount }), 5000);
      },
      onSuccess: (data) => {
        const Data = data.Data;
        const createdAt = new Date(Data.createdAt).toLocaleString(undefined, {
          month: "numeric",
          day: "numeric",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        });
        const updatedAt = new Date(Data.updatedAt).toLocaleString(undefined, {
          month: "numeric",
          day: "numeric",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        });
        setOrderData({
          ...Data,
          createdAt,
          updatedAt,
        });
      },
    }
  );

  if (isLoading)
    return (
      <div className="flex justify-center md:py-48 py-20">
        <h1 className="text-lg md:text-xl tracking-widest">Loading...</h1>
      </div>
    );
  if (!isLoading && error)
    return (
      <div className="flex justify-center md:py-48 py-20">
        <h1 className="text-lg md:text-xl tracking-widest">
          {error.status === 404 ? "Order not found" : "Error loading data"}
        </h1>
      </div>
    );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="flex justify-center"
    >
      <div className="flex flex-col gap-4 justify-center">
        <div className="formContent !mt-0">
          <h2 className="text-2xl md:text-4xl font-semibold text-center md:text-start">
            Order Receipt
          </h2>
          <p className="opacity-75 text-center text-base md:text-start md:w-3/4 pt-2">
            This page will update continuously to show new updated order
            information
          </p>
          <div className="p-7 my-5 rounded-2xl bg-dark flex items-center justify-between drop-shadow-xl ">
            <div className="space-y-5">
              <div>
                <h3 className="opacity-50 text-base whitespace-nowrap">
                  Order Created At
                </h3>
                <p className="font-medium text-lg">{orderData.createdAt}</p>
              </div>
              <div>
                <h3 className="opacity-50 text-base whitespace-nowrap">
                  Order Updated At
                </h3>
                <p className="font-medium text-lg">{orderData.updatedAt}</p>
              </div>
              <div>
                <h3 className="opacity-50 text-base whitespace-nowrap">
                  Roblox Username
                </h3>
                <p className="font-medium text-lg">
                  {orderData.robloxUsername}
                </p>
              </div>
              <div>
                <h3 className="opacity-50 text-base whitespace-nowrap">
                  Payment Method
                </h3>
                <p className="font-medium text-lg">
                  <span className="mr-2">
                    {orderData.paymentMethod.toLowerCase() === "card" ? (
                      <i class="fas fa-credit-card"></i>
                    ) : (
                      <i class="fas fa-coins"></i>
                    )}
                  </span>
                  {orderData.paymentMethod}
                </p>
              </div>
              <div>
                <h3 className="opacity-50 text-base whitespace-nowrap">
                  Email
                </h3>
                <p className="font-medium text-lg">{orderData.email}</p>
              </div>
              <div>
                <h3 className="opacity-50 text-base">
                  Purchas{orderData.isRobuxDelivered ? "ed" : "ing"}
                </h3>
                <h3 className="font-medium text-lg">
                  <span className="text-main">R$</span> {orderData.robuxAmount}
                </h3>
              </div>
              {/* Desktop 30% tax explanation */}
              <div className="hidden md:block">
                <Tooltip.Provider delayDuration={100}>
                  <Tooltip.Root>
                    <Tooltip.Trigger asChild>
                      <div className="flex gap-3">
                        <div>
                          <h3 className="opacity-50 text-base">
                            Receiv{orderData.isRobuxDelivered ? "ed" : "ing"}{" "}
                            <span className="text-red-500 opacity-100">*</span>
                          </h3>
                          <h3 className="font-medium text-lg">
                            <span className="text-main">R$</span>{" "}
                            {orderData.robuxAmountAfterTax}
                          </h3>
                        </div>
                        <div className="flex justify-center text-center justify-items-center">
                          <i
                            class="fa fa-question-circle text-2xl text-white/50"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    </Tooltip.Trigger>

                    <Tooltip.Portal>
                      <Tooltip.Content
                        className="text-black z-50 select-none rounded-[4px] bg-white px-[15px] py-[10px] text-[15px] leading-none shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity] data-[state=delayed-hamburgerOpen]:data-[side=bottom]:animate-slideUpAndFade data-[state=delayed-hamburgerOpen]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-hamburgerOpen]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-hamburgerOpen]:data-[side=top]:animate-slideDownAndFade"
                        sideOffset={5}
                        asChild
                      >
                        <div>
                          <h3>
                            Due to the robux being transferred via a gamepass,
                            Roblox will take 30%<br></br>This means{" "}
                            <span className="underline underline-offset-2">
                              <span className="">
                                you will recieve {orderData.robuxAmountAfterTax}{" "}
                                R$
                              </span>{" "}
                              <span className="text-main">(70%)</span>{" "}
                              <span className="">
                                and not {orderData.robuxAmount} R$
                              </span>
                            </span>
                          </h3>
                          <Tooltip.Arrow className="fill-white" />
                        </div>
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  </Tooltip.Root>
                </Tooltip.Provider>
              </div>
              {/* Mobile 30% tax explanation */}
              <div className="block md:hidden">
                <Popover.Root>
                  <Popover.Trigger asChild>
                    <div className="flex gap-3">
                      <div>
                        <h3 className="opacity-50 text-base">
                          Receiv{orderData.isRobuxDelivered ? "ed" : "ing"}{" "}
                          <span className="text-red-500 opacity-100">*</span>
                        </h3>
                        <h3 className="font-medium text-lg">
                          <span className="text-main">R$</span>{" "}
                          {orderData.robuxAmountAfterTax}
                        </h3>
                      </div>
                      <div className="flex justify-center text-center justify-items-center">
                        <i
                          class="fa fa-question-circle text-2xl text-white/50"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </Popover.Trigger>
                  <Popover.Portal>
                    <Popover.Content
                      className="z-50 rounded-lg bg-white text-black px-2 py-2 w-[275px] shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] will-change-[transform,opacity] data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=top]:animate-slideDownAndFade"
                      sideOffset={5}
                      asChild
                      side="bottom"
                    >
                      <div>
                        <h3>
                          Due to the robux being transferred via a gamepass,
                          Roblox will take 30%<br></br>This means{" "}
                          <span className="underline underline-offset-2">
                            <span className="">
                              you will recieve {orderData.robuxAmountAfterTax}{" "}
                              R$
                            </span>{" "}
                            <span className="text-main">(70%)</span>{" "}
                            <span className="">
                              and not {orderData.robuxAmount} R$
                            </span>
                          </span>
                        </h3>
                        <Popover.Arrow className="fill-white" />
                      </div>
                    </Popover.Content>
                  </Popover.Portal>
                </Popover.Root>
              </div>

              <div className="flex flex-col">
                <h3 className="opacity-50 text-base">
                  Amount{" "}
                  {orderData.paymentStatus === "PAID" ||
                  orderData.paymentStatus === "PAID_OVER"
                    ? "Paid"
                    : "Invoiced"}
                </h3>
                <div className="flex gap-2">
                  <h3 className="font-medium text-lg text-main whitespace-nowrap">
                    ${" "}
                    <span className="text-white">
                      {orderData.usdAmountInvoiced}
                    </span>
                  </h3>
                  <h3 className="font-medium text-lg">USD</h3>
                </div>
              </div>
              {/* {orderData.currency && (
                <div>
                  <h3 className="opacity-50 text-base whitespace-nowrap">
                    Currency Chosen
                  </h3>
                  <p className="font-medium text-lg">{orderData.currency}</p>
                </div>
              )} */}
              {orderData.robuxDeliveryStatus && (
                <>
                  {/* Desktop Robux delivery status explanation */}
                  <div className="hidden md:block">
                    <Tooltip.Provider delayDuration={100}>
                      <Tooltip.Root>
                        <Tooltip.Trigger asChild>
                          <div className="flex gap-3">
                            <div>
                              <h3 className="opacity-50 text-base whitespace-nowrap">
                                Robux Delivery Status
                              </h3>
                              <p className="font-medium text-lg">
                                {orderData.robuxDeliveryStatus}
                              </p>
                            </div>
                            <div className="flex justify-center text-center justify-items-center">
                              <i
                                class="fa fa-question-circle text-2xl text-white/50"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </Tooltip.Trigger>

                        <Tooltip.Portal>
                          <Tooltip.Content
                            className="text-black z-50 select-none rounded-[4px] bg-white px-[15px] py-[10px] text-[15px] leading-none shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity] data-[state=delayed-hamburgerOpen]:data-[side=bottom]:animate-slideUpAndFade data-[state=delayed-hamburgerOpen]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-hamburgerOpen]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-hamburgerOpen]:data-[side=top]:animate-slideDownAndFade"
                            sideOffset={5}
                            asChild
                          >
                            <div>
                              {(orderData.robuxDeliveryStatus === "Pending" && (
                                <h3>
                                  Your order is being processed.
                                  <br /> Please have patience, as this can take
                                  anywhere from 10 minutes to a couple of hours.
                                </h3>
                              )) ||
                                (orderData.robuxDeliveryStatus ===
                                  "Completed" && (
                                  <h3>
                                    Your order has successfully been sent to
                                    your roblox account.
                                    <br /> It could take a couple days for your
                                    robux to stop pending in your roblox
                                    account.
                                    <br />
                                    You can check the process{" "}
                                    <a
                                      href="https://www.roblox.com/transactions"
                                      target="_blank"
                                      rel="noreferrer"
                                      className="redirect"
                                    >
                                      here
                                    </a>
                                  </h3>
                                )) ||
                                (orderData.robuxDeliveryStatus === "Error" && (
                                  <h3>
                                    Your robux order has errored. Staff has been
                                    notified and your order will be either be
                                    completed manually,
                                    <br /> or you will be notified with steps to
                                    fulfill the order. Please do not worry!
                                    <br />
                                    <br />
                                    Reason: {orderData.robuxErrorReasonMessage}
                                  </h3>
                                )) ||
                                (orderData.robuxDeliveryStatus ===
                                  "Cancelled" && (
                                  <h3>
                                    Your robux order has been cancelled. Staff
                                    has been notified and your order will be
                                    either be completed manually,
                                    <br /> or you will be notified with further
                                    steps. Please do not worry!
                                  </h3>
                                ))}
                              <Tooltip.Arrow className="fill-white" />
                            </div>
                          </Tooltip.Content>
                        </Tooltip.Portal>
                      </Tooltip.Root>
                    </Tooltip.Provider>
                  </div>
                  {/* Mobile Robux delivery status explanation */}
                  <div className="block md:hidden">
                    <Popover.Root>
                      <Popover.Trigger asChild>
                        <div className="flex gap-3">
                          <div>
                            <h3 className="opacity-50 text-base whitespace-nowrap">
                              Robux Delivery Status
                            </h3>
                            <p className="font-medium text-lg">
                              {orderData.robuxDeliveryStatus}
                            </p>
                          </div>
                          <div className="flex justify-center text-center justify-items-center">
                            <i
                              class="fa fa-question-circle text-2xl text-white/50"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      </Popover.Trigger>
                      <Popover.Portal>
                        <Popover.Content
                          className="z-50 rounded-lg bg-white text-black px-2 py-2 w-[275px] shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] will-change-[transform,opacity] data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=top]:animate-slideDownAndFade"
                          sideOffset={5}
                          asChild
                          side="bottom"
                        >
                          <div>
                            {(orderData.robuxDeliveryStatus === "Pending" && (
                              <h3>
                                Your order is being processed. Please have
                                patience, as this can take anywhere from 10
                                minutes to a couple of hours.
                              </h3>
                            )) ||
                              (orderData.robuxDeliveryStatus ===
                                "Completed" && (
                                <h3>
                                  Your order has successfully been sent to your
                                  roblox account. It could take a couple days
                                  for your robux to stop pending in your roblox
                                  account. You can check the process{" "}
                                  <a
                                    href="www.roblox.com/transactions"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="redirect"
                                  >
                                    here
                                  </a>
                                </h3>
                              )) ||
                              (orderData.robuxDeliveryStatus === "Error" && (
                                <h3>
                                  Your robux order has errored. Staff has been
                                  notified and your order will be either be
                                  completed manually,
                                  <br /> or you will be notified with steps to
                                  fulfill the order. Please do not worry!
                                  <br />
                                  <br />
                                  Reason: {orderData.robuxErrorReasonMessage}
                                </h3>
                              )) ||
                              (orderData.robuxDeliveryStatus ===
                                "Cancelled" && (
                                <h3>
                                  Your robux order has been cancelled. Staff has
                                  been notified and your order will be either be
                                  completed manually,
                                  <br /> or you will be notified with further
                                  steps. Please do not worry!
                                </h3>
                              ))}
                            <Popover.Arrow className="fill-white" />
                          </div>
                        </Popover.Content>
                      </Popover.Portal>
                    </Popover.Root>
                  </div>
                </>
              )}
            </div>
            <div>
              <i className="fas fa-receipt md:mx-10 md:text-8xl text-6xl"></i>
            </div>
          </div>
        </div>

        <div className="formContent !mt-0">
          <div className="flex flex-col gap-4 text-center">
            <div className="px-4 py-3 bg-dark rounded-xl">
              {/* <!-- TrustBox widget - Review Collector --> */}
              <div ref={ref} className="trustpilot-widget">
                <a
                  href="https://www.trustpilot.com/review/cheapestrobux.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Trustpilot
                </a>
              </div>
              {/* <!-- End TrustBox widget --> */}
              <p>
                And if any issues occur, please contact staff on discord, or at
                support@cheapestrobux.com.
              </p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default withAuth(OrderDetails);
