import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import ReactSlider from "react-slider";
import useSWR from "swr";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// Constants
const MIN_ROBUX_AMOUNT = Number(process.env.REACT_APP_MIN_ROBUX_AMOUNT);
const MAX_ROBUX_AMOUNT = Number(process.env.REACT_APP_MAX_ROBUX_AMOUNT);

// SWR fetcher
const rateFetcher = async (...args) => {
  const req = await fetch(...args);
  if (!req.ok || req.status !== 200) {
    const error = new Error("An error occurred while fetching the data.");
    error.status = req.status;
    throw error;
  }
  return req.json();
};

const SelectAmount = ({ handleNext, purchaseDataChange }) => {
  const [realTimeSliderValue, setRealTimeSliderValue] =
    useState(MIN_ROBUX_AMOUNT);
  const [localPurchaseData, setLocalPurchaseData] = useState({
    robux: MIN_ROBUX_AMOUNT,
    usd: 0, // set after rate is fetched
  });
  const [ratePerThousand, setRatePerThousand] = useState();
  const [sliderTimeoutId, setSliderTimeoutId] = useState();
  const [firstFetchCompleted, setFirstFetchCompleted] = useState(false);

  const { error, isLoading, mutate } = useSWR(
    `${process.env.REACT_APP_MAIN_PROTOCOL}://api.${process.env.REACT_APP_MAIN_DOMAIN}/rate`,
    rateFetcher,
    {
      onSuccess: (data) => {
        const { rate } = data.Data;
        setRatePerThousand(rate);
        if (!firstFetchCompleted) {
          setLocalPurchaseData({
            ...localPurchaseData,
            usd: ((MIN_ROBUX_AMOUNT / 1000) * rate).toFixed(2),
          });
        }
        setFirstFetchCompleted(true);
      },
      refreshInterval: 2500,
      refreshWhenHidden: true,
      errorRetryCount: 6,
    }
  );

  const setValues = (rbx, dollars) => {
    setRealTimeSliderValue(rbx);
    setLocalPurchaseData({ robux: rbx, usd: dollars });
    purchaseDataChange("purchase", {
      robux: Number(rbx),
      usd: Number(dollars),
    });
  };

  const sliderChange = (e) => {
    let rbx = Number(e);
    setRealTimeSliderValue(rbx);
  };

  const afterSliderChange = (e) => {
    let rbx = Number(e);
    let dollarAmount = ((rbx / 1000) * ratePerThousand).toFixed(2);
    setValues(rbx, dollarAmount);
  };

  const rbxAmountChange = (e) => {
    let rbx = Number(e.target.value).toFixed(0);
    if (rbx > 10000 || rbx < 0) return;
    let dollarAmount = ((rbx / 1000) * ratePerThousand).toFixed(2);
    setValues(rbx, dollarAmount);
  };

  const dollarAmountChange = (e) => {
    let dollars = Number(e.target.value);
    if (dollars > 40 || dollars < 0) return;
    let rbxAmount = ((dollars / ratePerThousand) * 1000).toFixed(0);
    setValues(rbxAmount, dollars);
  };

  // Set the default values of rbx and dollars
  useEffect(() => {
    if (ratePerThousand)
      setValues(
        MIN_ROBUX_AMOUNT,
        Number(((MIN_ROBUX_AMOUNT / 1000) * ratePerThousand).toFixed(2))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ratePerThousand]);

  useEffect(() => {
    mutate();
  }, []);

  if (!isLoading && error) return <div>Error loading data</div>;

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <div>
        <div className="flex-col md:flex text-center md:text-left gap-3 md:gap-2">
          <h1 className="text-4xl font-semibold">Select an amount </h1>
          <h2 className="text-white/50 text-xl py-2 md:py-0">
            ${ratePerThousand} = 1,000 Robux
          </h2>
        </div>

        <div className="mb-5 w-full p-4 md:p-6 xl:w-3/4 relative mx-auto mt-5 md:mt-10 flex flex-col rounded-2xl bg-dark drop-shadow-xl border-2 border-white/25">
          {isLoading ? (
            <Skeleton height={250} />
          ) : (
            <>
              <div className="items-center mx-auto md:gap-16 font-bold !text-5xl w-full justify-center grid grid-cols-1 md:grid-cols-3">
                <div>
                  <p className="text-lg text-center md:text-left font-light opacity-75 md:mb-2">
                    Recieve
                  </p>
                  <div className="flex relative justify-center text-center md:text-left">
                    <span className="absolute left-0 top-5 text-main text-3xl">
                      R$
                    </span>
                    <div className="md:pl-4 pl-0">
                      <input
                        type="number"
                        //value={spendRobuxAmount}
                        value={realTimeSliderValue}
                        onChange={rbxAmountChange}
                        max={10000}
                        className="input !py-3 text-center max-w-[200px] !w-[150px] !md:w-fit [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      />
                    </div>
                  </div>
                  <div className="md:pl-4 pl-0">
                    <p className="text-center opacity-75 text-sm md:text-base">
                      Max: {MAX_ROBUX_AMOUNT.toLocaleString()}
                    </p>
                    <p className="text-center opacity-75 text-sm md:text-base">
                      Min: {MIN_ROBUX_AMOUNT}
                    </p>
                  </div>
                </div>

                <div className="flex items-center justify-center flex-col py-6 md:py-0">
                  <i className="fal fa-exchange"></i>
                </div>

                <div>
                  <p className="text-lg text-center md:text-left font-light opacity-75 md:mb-2">
                    Spend
                  </p>
                  <div className="flex relative justify-center text-center md:text-left">
                    <span className="absolute left-0 top-5 text-3xl text-main">
                      $
                    </span>
                    <input
                      type="text"
                      value={localPurchaseData.usd}
                      onChange={dollarAmountChange}
                      className="input !py-3 text-center max-w-[200px] !w-[150px] !md:w-fit"
                    />
                  </div>
                  <p className="text-center opacity-75 text-sm md:text-base">
                    Max: {Number(ratePerThousand) * (MAX_ROBUX_AMOUNT / 1000)}
                  </p>
                  <p className="text-center opacity-75 text-sm md:text-base">
                    Min:{" "}
                    {(
                      Number(ratePerThousand) *
                      (MIN_ROBUX_AMOUNT / 1000)
                    ).toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="mt-12">
                <ReactSlider
                  value={realTimeSliderValue}
                  max={MAX_ROBUX_AMOUNT}
                  min={MIN_ROBUX_AMOUNT}
                  step={50}
                  onChange={(value) => {
                    if (sliderTimeoutId) {
                      clearTimeout(sliderTimeoutId);
                    }
                    setSliderTimeoutId(
                      setTimeout(() => {
                        sliderChange(value);
                      }, 20)
                    );
                  }}
                  onAfterChange={afterSliderChange}
                  className="horizontal-slider"
                  thumbClassName="example-thumb"
                  trackClassName="example-track"
                />
                <div className="flex items-center justify-between mt-5 ">
                  <div>
                    <p className="text-sm md:text-lg">
                      {MIN_ROBUX_AMOUNT}{" "}
                      <span className="text-main text-sm md:text-lg">R$</span>
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <p className="text-sm md:text-lg">
                      {realTimeSliderValue.toLocaleString()}
                    </p>
                    <p className="text-main text-sm md:text-lg">R$</p>
                  </div>
                  <div>
                    <p className="text-sm md:text-lg">
                      {MAX_ROBUX_AMOUNT.toLocaleString()}{" "}
                      <span className="text-main text-sm md:text-lg">R$</span>
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="flex gap-4 pt-4 lg:pt-6 justify-center md:justify-end">
          <button
            type="button"
            className="cta !py-2 w-32 text-xl text-center"
            onClick={() => {
              if (localPurchaseData.robux < MIN_ROBUX_AMOUNT) {
                return toast.error(
                  `You must buy more than ${MIN_ROBUX_AMOUNT} robux`
                );
              } else if (
                localPurchaseData.robux > MAX_ROBUX_AMOUNT ||
                localPurchaseData.usd >
                  ratePerThousand * (MAX_ROBUX_AMOUNT / 1000)
              ) {
                return toast.error(
                  `You can only buy at max ${MAX_ROBUX_AMOUNT} robux`
                );
              }
              handleNext();
            }}
          >
            Next
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default SelectAmount;
