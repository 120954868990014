import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Hero from "../assets/hero.png";
import wave from "../assets/curve.svg";
import rbx2 from "../assets/robuxWatermark2.png";
import rbx from "../assets/robuxWatermark3.png";
import rbx4 from "../assets/robuxWatermark4.png";
import apple from "../assets/applepay.png";
import btc from "../assets/btc.png";
import visa from "../assets/visa.png";
import mastercard from "../assets/mastercard.png";
import discover from "../assets/discover.png";
import amex from "../assets/amex.png";
import Faq from "../components/Faq";
import { useAuth } from "../context/authContext";
import { useAuthModal } from "../context/authModalContext";
import useSWR from "swr";
import { useState } from "react";
import * as Separator from "@radix-ui/react-separator";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import useStock from "../hooks/useStock";

const statsFetcher = async (...args) => {
  const req = await fetch(...args);
  if (!req.ok || req.status !== 200) {
    const error = new Error("An error occurred while fetching the data.");
    error.status = req.status;
    throw error;
  }
  return req.json();
};

const rateFetcher = async (...args) => {
  const req = await fetch(...args, { credentials: "include" });
  if (!req.ok || req.status !== 200) {
    const error = new Error("An error occurred while fetching the data.");
    error.status = req.status;
    throw error;
  }
  return req.json();
};

const Home = () => {
  const [stats, setStats] = useState({});
  const [rate, setRate] = useState(4.7);

  const { error: statsError } = useSWR(
    `${process.env.REACT_APP_MAIN_PROTOCOL}://api.${process.env.REACT_APP_MAIN_DOMAIN}/stats`,
    statsFetcher,
    {
      onSuccess: (data) => {
        setStats(data.Data);
      },
    }
  );

  const { error: rateError, isLoading: isRateLoading } = useSWR(
    `${process.env.REACT_APP_MAIN_PROTOCOL}://api.${process.env.REACT_APP_MAIN_DOMAIN}/rate`,
    rateFetcher,
    {
      onSuccess: (data) => {
        const { rate } = data.Data;
        setRate(rate);
      },
    }
  );

  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div className="grid lg:grid-cols-2 lg:gap-32 gap-10 md:gap-5 container mx-auto pb-14">
        <div className="flex flex-col gap-10 justify-between h-fit">
          <div className="flex flex-col gap-5">
            <h1 className="text-4xl md:text-6xl duration-200 text-center md:text-start tracking-wider">
              the cheapest <span className="text-main">robux</span> available
            </h1>

            <p>
              CheapestRobux.com aims to offer you the most affordable and high
              quality Robux for the lowest price! Choosing from our many
              different payment methods, get Robux Delivered to your account for
              the cheapest price in just a few clicks!
            </p>

            {/* <div className="flex items-center gap-8 text-4xl cursor-pointer duration-200 justify-center md:justify-start">
              <Link to="/">
                <i className="hover:text-main fab fa-youtube-square"></i>
              </Link>
              <Link to="/">
                <i className="hover:text-main fab fa-twitter-square"></i>
              </Link>
              <a
                href={`${process.env.REACT_APP_MAIN_PROTOCOL}://api.${process.env.REACT_APP_MAIN_DOMAIN}/discord`}
              >
                <i className="hover:text-main fab fa-discord"></i>
              </a>
            </div> */}

            <Separator.Root
              decorative
              className="my-2 bg-white/50 data-[orientation=horizontal]:h-px data-[orientation=vertical]:h-full data-[orientation=horizontal]:w-full data-[orientation=vertical]:w-px"
            />

            <BuyRobuxWithStock />
          </div>
        </div>
        <div className="flex justify-center items-center relative md:pt-14 lg:pt-0 h-full">
          <img src={rbx} alt="hero" width={550} />
          <img
            src={Hero}
            alt="hero"
            className="hero z-20 absolute"
            width={550}
          />
        </div>
      </div>
      <div className="stats relative bg-dark-100/30">
        {/* <img src={wave} alt="wave" className="rotate-180" /> */}
        <div className="flex py-16 flex-col gap-16 container mx-auto lg:py-20">
          <div className="flex lg:justify-between justify-start gap-10 flex-wrap items-center tracking-widest">
            <div className="flex gap-3 items-center bg-black w-full justify-center py-5 px-3 md:w-auto md:mx-auto md:px-8 xl:px-6 xl:py-4 rounded-lg border-2 border-white/25 hover:border-white/50 bg-gradient-to-b from-dark-100 to-dark hover:bg-gradient-to-br duration-300">
              <i className="far fa-dollar-sign text-4xl text-main"></i>
              <div className="flex flex-col">
                <h1 className="!text-3xl">Lowest Prices</h1>
                {isRateLoading ? (
                  <Skeleton />
                ) : (
                  <p className="text-white/75 md:text-xl">
                    Currently:{" "}
                    <span className="text-main/75 duration-200 hover:underline hover:underline-offset-4">
                      ${rate} = 1,000 R$
                    </span>
                  </p>
                )}
              </div>
            </div>
            <div className="flex gap-3 items-center bg-black w-full justify-center py-5 px-3 md:w-auto md:mx-auto md:px-8 xl:px-8 xl:py-4 rounded-lg border-2 border-white/25 hover:border-white/50 bg-gradient-to-b from-dark-100 to-dark hover:bg-gradient-to-br duration-300">
              <i className="far fa-bolt text-4xl text-main"></i>
              <div className="flex flex-col">
                <h1 className="!text-3xl">Fast Delivery</h1>
                {isRateLoading ? (
                  <Skeleton />
                ) : (
                  <p className="text-white/75 md:text-xl">
                    Average: {""}
                    <span className="text-main/75 duration-200 hover:underline hover:underline-offset-4">
                      &lt;1 Minute
                    </span>
                  </p>
                )}
              </div>
            </div>
            <div className="flex gap-3 items-center bg-black w-full justify-center py-5 px-3 md:w-auto md:mx-auto md:px-8 xl:px-8 xl:py-4 rounded-lg border-2 border-white/25 hover:border-white/50 bg-gradient-to-b from-dark-100 to-dark hover:bg-gradient-to-br duration-300">
              <i className="far fa-smile text-4xl text-main"></i>
              <div className="flex flex-col">
                <h1 className="!text-3xl">Safe</h1>
                <div>
                  <p className="text-white/75 md:text-xl text-[16px]">
                    Support team{" "}
                    <span className="text-main/75 duration-200 hover:underline hover:underline-offset-4">
                      ready to help
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center text-center">
            <div className="grid grid-cols-2 grid-rows-3 justify-center gap-4 xl:grid-cols-none xl:grid-rows-none xl:flex xl:gap-14">
              <div className="flex gap-3          items-center bg-dark px-4 py-2 md:px-12 xl:px-10 xl:py-4 rounded-lg border-2 border-white/25 hover:border-white/50 bg-gradient-to-b from-dark-100 to-dark hover:bg-gradient-to-br duration-300">
                <img src={btc} alt="Crypto" className="min-w-[30px]" />
                <p className="lg:text-3xl font-semibold tracking-wide ">
                  Crypto
                </p>
              </div>
              <div className="flex justify-center items-center bg-dark px-4 py-2 md:px-12 xl:px-14 xl:py-4 rounded-lg border-2 border-white/25 hover:border-white/50 bg-gradient-to-b from-dark-100 to-dark hover:bg-gradient-to-br duration-300">
                <img src={apple} alt="apple pay" className="min-w-[80px]" />
              </div>
              <div className="flex justify-center items-center bg-dark px-4 py-2 md:px-12 xl:px-12 xl:py-4 rounded-lg border-2 border-white/25 hover:border-white/50 bg-gradient-to-b from-dark-100 to-dark hover:bg-gradient-to-br duration-300">
                <img
                  src={visa}
                  alt="visa"
                  className="min-w-[30px] w-32 lg:w-44"
                />
              </div>
              <div className="flex justify-center items-center bg-dark px-4 py-2 md:px-12 xl:px-12 xl:py-4 rounded-lg border-2 border-white/25 hover:border-white/50 bg-gradient-to-b from-dark-100 to-dark hover:bg-gradient-to-br duration-300">
                <img
                  src={mastercard}
                  alt="mastercard"
                  className="min-w-[30px] w-32 lg:w-36"
                />
              </div>
              <div className="flex justify-center items-center bg-dark px-4 py-2 md:px-12 xl:px-12 xl:py-4 rounded-lg border-2 border-white/25 hover:border-white/50 bg-gradient-to-b from-dark-100 to-dark hover:bg-gradient-to-br duration-300">
                <img
                  src={amex}
                  alt="amex"
                  className="min-w-[30px] w-32 lg:w-40"
                />
              </div>
              <div className="flex justify-center items-center bg-dark px-4 py-2 md:px-12 xl:px-12 xl:py-4 rounded-lg border-2 border-white/25 hover:border-white/50 bg-gradient-to-b from-dark-100 to-dark hover:bg-gradient-to-br duration-300">
                <img
                  src={discover}
                  alt="discover"
                  className="min-w-[30px] w-32 lg:w-40"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <img src={wave} alt="wave" /> */}
      </div>
      {/* <div className="container mx-auto my-16">
        <h1 className="mb-8">Recent Purchases</h1>
        <RecentPurchase />
      </div> */}
      <div className="container flex justify-center mx-auto py-6 lg:py-16">
        <div className="flex flex-col items-center gap-5">
          <h1 className="mb-8 text-center md:text-start">
            How Do I Purchase <span className="text-main">Robux</span>?
          </h1>
          <div className="grid grid-cols-1 gap-5">
            {/* when vid gets put in add     lg:grid-cols-2     above */}
            <div className="flex relative flex-col max-w-[500px]">
              <div className="relative border-l-4 border-dashed border-main h-fit pb-8">
                <div className="rounded-full h-5 w-5 border-4 bg-dark border-main absolute -left-3 -top-3">
                  <span className="w-max -top-[10px] font-medium text-lg left-[28px] absolute text-main">
                    Step 1
                  </span>
                </div>
                <div className="pl-5 pt-3">
                  <h3 className="text-2xl font-semibold">Choose Amount</h3>
                  <span>
                    Our Robux is super cheap. Choose an amount that suits you
                    and continue.
                  </span>
                </div>
              </div>
              <div className="relative border-l-4 border-dashed border-main h-fit pb-8">
                <div className="rounded-full h-5 w-5 border-4 bg-dark border-main absolute -left-3 -top-3">
                  <span className="w-max -top-[10px] font-medium text-lg left-[28px] absolute text-main">
                    Step 2
                  </span>
                </div>
                <div className="pl-5 pt-3">
                  <h3 className="text-2xl font-semibold">
                    Follow the delivery instructions
                  </h3>
                  <span>
                    Follow our simple guide and make a gamepass in just 30
                    seconds.
                  </span>
                </div>
              </div>
              <div className="relative border-l-4 border-dashed border-transparent h-fit">
                <div className="rounded-full h-5 w-5 border-4 bg-dark border-main absolute -left-3 -top-3">
                  <span className="w-max -top-[10px] font-medium text-lg left-[28px] absolute text-main">
                    Step 3
                  </span>
                </div>
                <div className="pl-5 pt-3">
                  <h3 className="text-2xl font-semibold">
                    Purchase Your Robux
                  </h3>
                  <span>
                    Purchase and receive your Robux! Your gamepass will be
                    purchased and your funds will be pending in your account.
                  </span>
                </div>
              </div>
            </div>
            {/* <iframe
            className="w-full h-64 rounded-md shadow-md"
            src="https://www.youtube-nocookie.com/embed/GNGhVYEXNK0"
            title="How to Create a Gamepass on ROBLOX"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe> */}
          </div>
        </div>
      </div>
      {!statsError && (
        <div className="py-20 bg-dark-100/30 stats">
          <div className="container mx-auto gap-10 flex-wrap flex items-center justify-evenly">
            <div className="flex flex-col items-center gap-2">
              <h3 className="text-5xl text-main font-bold text-center">
                {stats.robuxBought || <Skeleton width={200} />}
              </h3>
              <span className="font-semibold text-white text-center text-lg">
                Robux Bought
              </span>
            </div>
            {/* 
            <div className="flex flex-col items-center gap-2">
              <h3 className="text-5xl text-main font-bold text-center">
                {stats.transactionsMade || <Skeleton width={200} />}
              </h3>
              <span className="font-semibold text-white text-center text-lg">
                Transactions Made
              </span>
            </div>
            */}
            <div className="flex flex-col items-center gap-2">
              <h3 className="text-5xl text-main font-bold text-center">
                {stats.registeredAccounts || <Skeleton width={200} />}
              </h3>
              <span className="font-semibold text-white text-center text-lg">
                Registered Accounts
              </span>
            </div>
          </div>
        </div>
      )}
      {/* <div className="container mx-auto py-16">
        <h1 className="mb-8">Reviews</h1>
        <div className="recentPurchase !gap-3 max-w-md">
          <div className="z-20 flex items-center justify-between">
            <div>
              <h2 className="text-3xl font-semibold">Review Title</h2>
              <h3 className="text-sm text-gray-400">kieran6k</h3>
            </div>
            <div className="flex items-center gap-0.5 text-main">
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star !text-white"></i>
            </div>
          </div>
          <div className="z-20">
            <span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
              tempus commodo dignissim. Nullam sit amet vulputate sem.
              Vestibulum luctus, sem id interdum auctor, orci mi dictum augue,
              sit amet feugiat metus mi gravida mi. Vivamus dignissim odio sit
              amet felis euismod, non interdum ipsum tempus. Ut arcu mi,
              pellentesque non gravida elementum, sagittis a odio. Sed
              pellentesque tincidunt enim, eget tincidunt neque tempor eu.{" "}
            </span>
          </div>
        </div>
      </div> */}
      <Faq />
    </motion.div>
  );
};

const RecentPurchase = () => {
  return (
    <div className="recentPurchase">
      <img
        src={rbx2}
        alt=""
        className="absolute z-10 -bottom-16 -right-12  opacity-60"
      />
      <div className="z-20">
        <h2 className="text-4xl font-bold">
          200 <span className="text-main">R$</span>
        </h2>
        <h3 className="text-sm text-gray-400">kieran6k</h3>
      </div>
      <div className="z-20">
        <h3 className="text-sm">2 Minutes Ago</h3>
      </div>
    </div>
  );
};

const BuyRobuxWithStock = () => {
  const { setAuthModalOpen } = useAuthModal();
  const { isSignedIn } = useAuth();
  const { stock, stockLastUpdated, isStockLoading, isStockError } = useStock(0);

  return (
    <div className="flex flex-col">
      <div className="flex space-x-2 justify-center md:justify-start">
        <p className="text-xl md:text-4xl">Stock:</p>
        <div className="md:pl-2 flex justify-center items-center">
          <img
            src={rbx4}
            alt="rbx"
            className="md:w-[35px] md:h-[35px] w-[25px] h-[25px]"
          />
        </div>
        <p className="text-xl md:text-4xl text-white/75">
          {isStockLoading ? <Skeleton /> : stock}
          {!isStockLoading && isStockError && "Error"}
        </p>
      </div>
      <div className="py-2 flex justify-center md:justify-start">
        {stockLastUpdated && (
          <p>
            Last updated:{" "}
            <span className="text-white/75">{stockLastUpdated}</span>
          </p>
        )}
      </div>
      <div className="flex justify-center md:justify-start">
        {isSignedIn ? (
          <Link
            to="/buy"
            className="cta !w-52 md:!w-96 md:!py-5 text-center md:text-3xl text-xl"
          >
            Buy Robux
          </Link>
        ) : (
          <button
            className="cta !w-52 md:!w-96 md:!py-5 text-center md:text-3xl text-xl"
            onClick={setAuthModalOpen}
          >
            Buy Robux
          </button>
        )}
      </div>
    </div>
  );
};

export default Home;
