import { useState } from "react";
import { motion } from "framer-motion";
import Backdrop from "../Backdrop";
import useSWR from "swr";
import { toast } from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const dropInOut = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};

// SWR Fetcher
const accountFetcher = async (...args) => {
  const req = await fetch(...args, { credentials: "include" });
  if (!req.ok || req.status !== 200) {
    const error = new Error("An error occurred while fetching the data.");
    error.status = req.status;
    throw error;
  }
  return req.json();
};

function Step1({ handleNext, handleBack, username, purchaseDataChange }) {
  const [addAccountModalOpened, setAddAccountModalOpen] = useState(false);
  const [accounts, setAccounts] = useState([]);

  const { data, error, isLoading, mutate } = useSWR(
    `${process.env.REACT_APP_MAIN_PROTOCOL}://rbx.${process.env.REACT_APP_MAIN_DOMAIN}/get-roblox-accounts`,
    accountFetcher,
    {
      onSuccess: (data) => {
        if (data.Data.length === 0) {
          purchaseDataChange("user", { username: null, id: null });
          setAccounts();
          return;
        }
        const mappedArray = data.Data.map((obj, idx) => ({
          headshot: `${process.env.REACT_APP_MAIN_PROTOCOL}://rbx.${process.env.REACT_APP_MAIN_DOMAIN}/headshot/${obj.robloxUserId}`,
          username: obj.robloxUsername,
          selected: idx === 0,
        }));
        purchaseDataChange("user", {
          username: mappedArray[0].username,
          id: data.Data[0].robloxUserId,
        });
        setAccounts(mappedArray);
      },
    }
  );

  if (!isLoading && error) return <div>Error loading data</div>;

  const setSelectedAccount = (e) => {
    const selectedUsername = e.target.innerText;
    if (!selectedUsername) return;
    setAccounts((prevAccounts) =>
      prevAccounts.map((account) =>
        account.username === selectedUsername
          ? { ...account, selected: true }
          : { ...account, selected: false }
      )
    );
    for (const user of data.Data) {
      if (user.robloxUsername === selectedUsername) {
        purchaseDataChange("user", {
          id: user.robloxUserId,
          username: selectedUsername,
        });
        break;
      }
    }
  };

  const closeModal = () => setAddAccountModalOpen(false);
  const openModal = () => setAddAccountModalOpen(true);

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <div>
        <div className="flex gap-2 flex-col">
          <h2 className="text-4xl font-semibold text-center md:text-start">
            Please select your account
          </h2>
          <p className="opacity-75 text-center md:text-start">
            The funds will get delivered to this account, so please make sure
            its correct!
          </p>
        </div>

        <div className="flex flex-col gap-3 py-6 drop-shadow-xl">
          {isLoading ? (
            <SkeletonAccount />
          ) : (
            accounts &&
            accounts.map((account, idx) => {
              return (
                <Account
                  key={idx}
                  {...account}
                  setAccountSelected={setSelectedAccount}
                  refetchAccounts={mutate}
                />
              );
            })
          )}
        </div>

        <div className="flex py-2 pb-6 md:pb-0 justify-center md:justify-start">
          <button
            type="button"
            onClick={openModal}
            className="cta !py-2 text-xl text-center whitespace-nowrap"
          >
            Add Account
          </button>
        </div>

        {addAccountModalOpened && (
          <AddAccount closeModal={closeModal} refetchAccounts={mutate} />
        )}

        <div className="flex gap-4 justify-center md:justify-end">
          <button
            type="button"
            className="cta-cancel !py-2 w-32 text-xl text-center"
            onClick={handleBack}
          >
            Back
          </button>
          <button
            type={!username ? "button" : "submit"}
            className="cta !py-2 w-32 text-xl text-center"
            onClick={() => {
              if (!username) {
                if (!accounts) {
                  return toast.error("Please add an account!");
                }
                return toast.error("Please select an account!");
              }
              handleNext();
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </motion.div>
  );
}

function Account({
  username,
  headshot,
  selected,
  setAccountSelected,
  refetchAccounts,
}) {
  const sendDeleteAccount = async () => {
    try {
      const req = await fetch(
        `${process.env.REACT_APP_MAIN_PROTOCOL}://rbx.${process.env.REACT_APP_MAIN_DOMAIN}/remove-roblox-account`,
        {
          method: "DELETE",
          credentials: "include",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            username,
          }),
        }
      );
      if (req.status !== 200)
        throw (await req.json()).Message || "Unknown error occured";
      refetchAccounts();
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className="flex gap-2 justify-center md:justify-start">
      <button
        type="button"
        value={username}
        onClick={setAccountSelected}
        className="drop-shadow-xl flex"
      >
        <div
          className={`${selected ? "border-main" : "border-white/20 "} flex px-4 py-2 bg-dark rounded-md gap-4 items-center border-2 hover:border-main duration-200`}
        >
          <button type="button" onClick={sendDeleteAccount}>
            <i className="fal fa-trash fa-lg text-white/50 hover:text-white/20 duration-200 "></i>
          </button>
          <img
            src={headshot}
            alt="roblox headshot"
            className="rounded-full w-[50px] h[50px] md:w-[100px] md:h-[100px] border-2 border-white/20 pointer-events-none"
          />
          <p className="font-light tracking-wide text-left text-lg md:text-2xl text-white break-words w-[12ch]">
            {username}
          </p>
        </div>
      </button>
    </div>
  );
}

function SkeletonAccount() {
  return (
    <div className="flex gap-2 justify-center md:justify-start">
      <div className="drop-shadow-xl flex">
        <div className="border-white/20 border-2 flex px-4 py-2 bg-dark rounded-md gap-3 items-center">
          <div className="md:block hidden">
            {/* DESKTOP */}
            <Skeleton
              circle
              height={100}
              width={100}
              className="border-2 border-white/20"
            />
          </div>
          <div className="md:hidden block">
            {/* MOBILE */}
            <Skeleton
              circle
              height={50}
              width={50}
              className="border-2 border-white/20"
            />
          </div>

          <div className="md:block hidden">
            {/* DESKTOP */}
            <Skeleton height={20} width={225} />
          </div>
          <div className="md:hidden block ">
            {/* MOBILE */}
            <Skeleton height={20} width={100} />
          </div>
        </div>
      </div>
    </div>
  );
}

function AddAccount({ closeModal, refetchAccounts }) {
  const [accountName, setAccountName] = useState();

  const updateName = (e) => setAccountName(e.target.value);

  const sendAddAccount = async () => {
    try {
      const req = await fetch(
        `${process.env.REACT_APP_MAIN_PROTOCOL}://rbx.${process.env.REACT_APP_MAIN_DOMAIN}/add-roblox-account`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            username: accountName,
          }),
        }
      );
      if (req.status !== 200)
        throw (await req.json()).Message || "Unknown error occured";
      refetchAccounts();
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <Backdrop onClick={closeModal}>
      <motion.form
        className="modal max-w-[500px]"
        onClick={(e) => e.stopPropagation()}
        variants={dropInOut}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="flex justify-between">
          <div className="flex flex-col gap-2 mb-1">
            <h3 className="text-xl font-semibold">Add Account</h3>
            <p className="font-normal">
              Please enter the Roblox username below
            </p>
          </div>
          <div
            onClick={closeModal}
            className="cursor-pointer hover:text-white w-7 h-7 flex items-center justify-center"
          >
            <i className="fal fa-times text-xl"></i>
          </div>
        </div>
        <div className="mt-5 flex flex-col gap-3">
          <input
            type="text"
            placeholder="Roblox Username"
            onChange={updateName}
            className="w-full"
          />
          <div className="flex flex-col md:flex-row w-full text-lg items-center font-medium gap-2 mt-5 justify-end">
            <button
              onClick={closeModal}
              type="button"
              className="cta-cancel !py-2 !w-full md:!w-fit "
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={() => {
                closeModal();
                sendAddAccount();
              }}
              className="cta !py-2 !w-full md:!w-fit"
            >
              Add Account
            </button>
          </div>
        </div>
      </motion.form>
    </Backdrop>
  );
}

export default Step1;
